import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import React, { useState, useEffect } from "react";
import "./style.css";
import { useDispatch } from "react-redux";
import { fetchData } from "../redux/apiSlice";
import { toastify, userlogout, clearuser } from '../redux/controller';
import 'react-toastify/dist/ReactToastify.css';
import { format } from 'date-fns';
import Xicon from "../assets/Delete Icon.svg";

import { saveAs } from 'file-saver';
import { unparse } from 'papaparse';

export default function Reservations() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sortConfig, setSortConfig] = useState({
    key: "",
    direction: ""
  });

  let Navigate = useNavigate();
  const [step1inputField, setstep1InputField] = useState({
    cancelreason: ""
  })
  const [cancelreservation, setcancelreservation] = useState({});
  const [totalReservations, setTotalReservations] = useState(0);
  const [showCancelLoader, setCancelLoader] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(true);

  const [totalPayment, setTotalPayment] = useState('0');
  const [totalPaidReservations, setTotalPaidReservations] = useState(0);
  const [showCancel, setShowCancel] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const [Reservationsdata, setReservationsdata] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [status, setStatus] = useState('Loading...');

  // const usertoken = useSelector((state) => state.userdata.usertoken);
  const usertoken = localStorage.getItem('usertoken');


  const [perpage, setPerPage] = useState(10);

  const inputperPage = (event) => {
    setCurrentPage(1);
    setPerPage(event.target.value);
  };

  useEffect(() => {
    getReservationsdata();
  }, [])

  useEffect(() => {
    getAllHunters(searchInput, currentPage);
  }, [currentPage, perpage]);

  const inputsHandler = (e) => {
    console.log(e.target.name);
    setstep1InputField((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  function convertdate(date) {
    return date.substring(5, 7) + "/" + date.substring(8, 10) + "/" + date.substring(0, 4)
  }

  function setShowCancelReservation(reservation) {
    setcancelreservation(reservation)
    setShowCancel(true)
  }

  async function getAllHunters(searchQuery = "", page = 1) {
    setReservationsdata([]);
    setStatus('Loading...')
    var reservationList = await dispatch(
      fetchData({
        apiurl: "/reservation/allreservations",
        page: page,
        limit: perpage,
        search: searchQuery,
        headers: { "x-access-token": usertoken }
      })
    );
    console.log("reservation list", reservationList);
    if (reservationList && reservationList.payload && reservationList.payload.status === 200) {

      if (reservationList.payload.totalReservations === 0) {
        setStatus('No reservations!');
        setCurrentPage(1)
        setTotalReservations(0)
      } else {
        setStatus('');
        const huntersListLocal = reservationList.payload.reservations.map((reservations) => {

          // const selectedDate = new Date(reservations.reservations.cart[0].selecteddate);
          const formattedDate = new Date(reservations.reservations.cart[0].selecteddate)
          // const formattedDate = `${String(selectedDate.getDate()).padStart(2, '0')}/${String(selectedDate.getMonth() + 1).padStart(2, '0')}/${selectedDate.getFullYear()}`;

          var paymentId = reservations.reservations.payment != null ? reservations.reservations.payment.paymentdata != null ? reservations.reservations.payment.paymentdata.paymenttId.length > 0 ? reservations.reservations.payment.paymentdata.paymenttId : '--' : '--' : '--'

          var paymentTotal = reservations.reservations.payment != null ? reservations.reservations.payment.paymentdata != null ? reservations.reservations.payment.paymentdata.paymentAmount.length > 0 ? reservations.reservations.payment.paymentdata.paymentAmount : '0' : '0' : '0'

          return {
            reservation: reservations.reservations,
            id: reservations.reservations._id,
            cart: reservations.reservations.cart,
            payment_id: paymentId,
            hunter: reservations.hunter == null ? {} : reservations.hunter,
            hunter_name: reservations.hunter == null ? '--' : reservations.hunter?.firstname + " " + reservations.hunter?.lastname,
            landowner_name: reservations.landowner == null ? '--' : reservations.landowner?.firstname + " " + reservations.landowner?.lastname,
            property_name: reservations.reservations.cart[0].property.title,
            reservation_date: formattedDate,
            slot_id: reservations.reservations.cart[0].selectedslot.name,
            cost: reservations.reservations.paymentdetail.total ?? '0',
            coupon_discount: reservations.reservations?.couponapplied?.couponamount ?? '--',
            coupon_discount_type: reservations.reservations?.couponapplied?.discountType ?? "--",
            coupon_applied: reservations.reservations?.couponapplied?.couponcode ?? "",
            total: paymentTotal,
            reservation_id: reservations.reservations._id,
            createdAt: convertdate(reservations.reservations.createdAt),
            createdAts: reservations.reservations.createdAt,
            reservation_status: reservations.reservations.orderstatus === 'cancelled' ? 'Cancelled' : reservations.reservations.orderstatus === 'pending' ? 'Pending' : 'Booked',
            action: "cancel"
          };
        });



        console.log('huntersListLocal')
        console.log(huntersListLocal)
        setReservationsdata(huntersListLocal);
        setTotalReservations(reservationList.payload.totalReservations);
        setStatus('');
      }
    } else {
      if (reservationList.payload.response && reservationList.payload.response.message) {
        if (reservationList.payload.response.message === "Invalid token provided!") {
          toastify({ message: 'Session expired' });
          userlogout();
          navigate('/');
        }
      }
    }
  }



  async function getReservationsdata() {

    var reservationsData = await dispatch(
      fetchData({
        apiurl: "/reservation/gettotalreservationsdata",
        headers: { "x-access-token": usertoken }
      })
    );
    console.log("reservationsData", reservationsData);
    setLoadingDetails(false)
    if (reservationsData && reservationsData.payload && reservationsData.payload.status === 200) {
      if (reservationsData.payload.totalNumberOfDirectreservations) {
        setTotalPaidReservations(reservationsData.payload.totalNumberOfDirectreservations)
      }
      if (reservationsData.payload.directBookingsPricePaid) {
        setTotalPayment(reservationsData.payload.directBookingsPricePaid.toFixed(2))
      }
    } else {
      if (reservationsData.payload.response && reservationsData.payload.response.message) {
        if (reservationsData.payload.response.message === "Invalid token provided!") {
          toastify({ message: 'Session expired' });
          userlogout();
          navigate('/');
        }
      }
    }
  }

  const handleEditClick = (reservationdetails) => {
    Navigate(`/reservationdetails/${reservationdetails.id}`, { state: { reservationdetails } });
  };



  function handlepopupclose() {
    setShowCancel(false)
  }

  async function handlecancelreservation() {
    setShowCancel(false)
    setCancelLoader(true)

    var cancelledreservation = await dispatch(fetchData({
      apiurl: "/reservation/cancel",
      reservationid: cancelreservation.id,
      cancellationreason: step1inputField.cancelreason,
      headers: { "x-access-token": usertoken }
    }))
    console.log(cancelledreservation)
    setstep1InputField({
      'cancelreason': ""
    })
    setCancelLoader(false)
    if (cancelledreservation && cancelledreservation.payload && cancelledreservation.payload.status == 200) {
      getAllHunters(searchInput, currentPage);
      if (cancelreservation.reservation != null) {
        if (cancelreservation.reservation.userid != null) {
          if (cancelreservation.reservation.userid.udid != null) {
            var cancelledreservationnotification = await dispatch(fetchData({
              apiurl: "/reservation/send/notification",
              "messagingtoken": cancelreservation.reservation.userid.udid,
              "title": "Admin cancelled your reservation",
              "message": cancelreservation.cart[0].property.title + " : " + step1inputField.cancelreason,
              "propertyname": cancelreservation.cart[0].property.title,
              headers: { "x-access-token": usertoken }
            }))
          }
        }
      }

      // getreservations()
    }
    else {
      console.log(cancelledreservation.payload)
      if (cancelledreservation.payload.response && cancelledreservation.payload.response.data && cancelledreservation.payload.response.data.message) {
        toastify({ message: cancelledreservation.payload.response.data.message })
        if (cancelledreservation.payload.response.data.message == "Invalid token provided!") {
          clearuser()
          userlogout()
          Navigate('/')
          window.location.reload()
        }
      }
      else if (cancelledreservation.payload && cancelledreservation.payload.message) {
        toastify({ message: cancelledreservation.payload.message })
      }
      return null
    }
  }

  const handlereservationupcomingidPageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  const handleSearch = () => {
    // setReservationsdata([]);
    setStatus('Loading...')
    getAllHunters(searchInput, 1);
    setCurrentPage(1);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleClearSearch = () => {

    setReservationsdata([]);
    setStatus('Loading...')
    setSearchInput("")
    getAllHunters("", 1)
    setCurrentPage(1)
    setTotalReservations(0)
  };

  function gotopropertydetail(reservation, today) {
    console.log(reservation)
    var element = reservation.cart[0]
    var url = today ? "/propertydetailsmap?index=" + reservation.id + "&all=" + element.property._id : "/propertydetailsmap?index=" + reservation.id
    navigate(url, {
      state: {
        property: element.property,
        id: element.property.id,
        property_name: element.property.title,
        category: element.property.category,
        stateLocal: element.property.state,
        county: element.property.country,
        city: element.property.city,
        zipcode: element.property.zipcode,
        totalarea: element.property.totalarea,
        action: element.property.approved == null ? 'Pending' : element.property.approved === "false" ? 'Rejected' : element.property.approved === "paused" ? 'Paused' : 'Approved',
        landownerId: element?.property.userid
      }
    })
  }

  function sortreservations(type) {
    let direction = "ascending";
    if (sortConfig.key === type && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    console.log("type")
    console.log(type)
    console.log(direction)
    setSortConfig({ key: type, direction });
  }

  function gotohunterdetail(userdetail) {
    console.log(userdetail)
    navigate("/huntersdetails", { state: { id: userdetail._id, hunter_name: userdetail.firstname + " " + userdetail.lastname, email: userdetail.email, phone_number: userdetail.phonenumber, createdAt: userdetail.createdAt } })
  }

  const getCurrentPageData = () => {
    console.log(Reservationsdata)
    const filteredData = Reservationsdata.sort((a, b) => {
      console.log("asdklsajd", a);
      switch (sortConfig.key) {
        case "hunter_name":
          return sortConfig.direction === "ascending"
            ? a.hunter_name.localeCompare(b.hunter_name)
            : b.hunter_name.localeCompare(a.hunter_name);
        case "landowner_name":
          return sortConfig.direction === "ascending"
            ? a.landowner_name.localeCompare(b.landowner_name)
            : b.landowner_name.localeCompare(a.landowner_name);
        case "property_name":
          return sortConfig.direction === "ascending"
            ? a.property_name.localeCompare(b.property_name)
            : b.property_name.localeCompare(a.property_name);
        // case "invite_sent_date":
        //   return sortConfig.direction === "ascending"
        //     ? new Date(a.invite_sent_date) - new Date(b.invite_sent_date)
        //     : new Date(b.invite_sent_date) - new Date(a.invite_sent_date);
        case "createdAt":
          return sortConfig.direction === "ascending"
            ? new Date(a.createdAt) -
            new Date(b.createdAt)
            : new Date(b.createdAt) -
            new Date(a.createdAt);
        case "ReservationDate":
          return sortConfig.direction === "ascending"
            ? new Date(a.cart[0].selecteddate) -
            new Date(b.cart[0].selecteddate)
            : new Date(b.cart[0].selecteddate) -
            new Date(a.cart[0].selecteddate);
        default:
          return 0;
      }
    });
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredData;
  };


  const [loading, setLoading] = useState(false);
  const downloadCSV = async () => {

    setLoading(true);
    // Specify the CSV headers (columns)
    const headers = ["Hunter Name", "Hunter Phone No.", "Hunter Email", "Landowner Name", "Property Name", "Purchase Date", "Reservation Date", "Slot Id", "Cost", "Coupon Applied", "Coupon Discount", "Payment Id", "Reservation Status"]; // Update with your table's actual column names

    // Map the table data to match the CSV headers


    var reservationList = await dispatch(
      fetchData({
        apiurl: "/reservation/downloadreservations",
        headers: { "x-access-token": usertoken }
      })
    );
    console.log("download reservation list", reservationList);

    setLoading(false);
    if (reservationList && reservationList.payload && reservationList.payload.status === 200) {

      const huntersListLocal = reservationList.payload.reservations.map((reservations) => {

        // const selectedDate = new Date(reservations.reservations.cart[0].selecteddate);
        const formattedDate = new Date(reservations.reservations.cart[0].selecteddate)
        // const formattedDate = `${String(selectedDate.getDate()).padStart(2, '0')}/${String(selectedDate.getMonth() + 1).padStart(2, '0')}/${selectedDate.getFullYear()}`;

        var paymentId = reservations.reservations.payment != null ? reservations.reservations.payment.paymentdata != null ? reservations.reservations.payment.paymentdata.paymenttId.length > 0 ? reservations.reservations.payment.paymentdata.paymenttId : '--' : '--' : '--'

        var paymentTotal = reservations.reservations.payment != null ? reservations.reservations.payment.paymentdata != null ? reservations.reservations.payment.paymentdata.paymentAmount.length > 0 ? reservations.reservations.payment.paymentdata.paymentAmount : '0' : '0' : '0'

        return {
          reservation: reservations.reservations,
          id: reservations.reservations._id,
          cart: reservations.reservations.cart,
          payment_id: paymentId,
          hunter: reservations.hunter == null ? {} : reservations.hunter,
          hunter_name: reservations.hunter == null ? '--' : reservations.hunter?.firstname + " " + reservations.hunter?.lastname,
          landowner_name: reservations.landowner == null ? '--' : reservations.landowner?.firstname + " " + reservations.landowner?.lastname,
          property_name: reservations.reservations.cart[0].property.title,
          reservation_date: formattedDate,
          slot_id: reservations.reservations.cart[0].selectedslot.name,
          cost: reservations.reservations.paymentdetail.total ?? '0',
          coupon_discount: reservations.reservations?.couponapplied?.couponamount ?? '--',
          coupon_discount_type: reservations.reservations?.couponapplied?.discountType ?? "--",
          coupon_applied: reservations.reservations?.couponapplied?.couponcode ?? "",
          total: paymentTotal,
          reservation_id: reservations.reservations._id,
          createdAt: convertdate(reservations.reservations.createdAt),
          createdAts: reservations.reservations.createdAt,
          reservation_status: reservations.reservations.orderstatus === 'cancelled' ? 'Cancelled' : reservations.reservations.orderstatus === 'pending' ? 'Pending' : 'Booked',
          action: "cancel"
        };
      });


      const csvData = huntersListLocal.map((reservation) => {


        let reservationDates = reservation.cart.map((name) =>
          convertdate(name.selecteddate)
        );

        let reservationSlotId = reservation.cart.map((name) =>
          name.selectedslot.name
        );

        let couponDisCount =
          reservation.coupon_discount_type === '--' ?
            "--"
            :
            reservation.coupon_discount_type === 'percentage' ?
              `${reservation.coupon_discount}%`
              :
              `$${reservation.coupon_discount}`


        return {
          "Hunter Name": reservation.hunter_name,
          "Hunter Phone No.": reservation.hunter != null ? reservation.hunter.phonenumber != null ? reservation.hunter.phonenumber.length > 0 ? reservation.hunter.phonenumber : '--' : '--' : '--',
          "Hunter Email": reservation.hunter != null ? reservation.hunter.email != null ? reservation.hunter.email : '--' : '--',
          "Landowner Name": reservation.landowner_name,
          "Property Name": reservation.property_name,
          "Purchase Date": reservation.createdAt,
          "Reservation Date": reservationDates,
          "Slot Id": reservationSlotId,
          "Cost": `$${reservation.cost}`,
          "Coupon Applied": reservation.coupon_applied ? reservation.coupon_applied : reservation.hunter && reservation.hunter.subscriptions && reservation.hunter.subscriptions.length && reservation.hunter.subscriptions[0].no_of_memberships ? "Membership" : "--",
          "Coupon Discount": couponDisCount,
          "Payment Id": reservation.payment_id,
          "Reservation Status": reservation.reservation_status,
        }
      }
      );



      // Convert to CSV using papaparse
      const csv = unparse({ fields: headers, data: csvData });

      // Create a Blob object from the CSV data
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      // Trigger file download using file-saver
      saveAs(blob, "reservations-data.csv");

    }


  };

  const itemsPerPage = 10;
  // // Calculate start and end index for the current page
  // const [reservationupcomingidcurrentPage, setReservationupcomingidcurrentPage] = useState(0);
  // const reservationupcomingidperPage = 5;
  // const reservationupcomingidstartIndex = reservationupcomingidcurrentPage * reservationupcomingidperPage;
  // const reservationupcomingidendIndex = reservationupcomingidstartIndex + reservationupcomingidperPage;

  // // Get data for the current page
  // const reservationupcomingidcurrentData = Reservationsdata.slice(reservationupcomingidstartIndex, reservationupcomingidendIndex);
  return (
    <div className="reservation_div">

      <div className="dashboard-main">
        <div className="dashboard-section">
          <div className="dashboard-subsections" >
            <div className="dashboard-contant">
              <p className="title">Direct bookings</p>
              <h3> {loadingDetails ? <span className="loading-dots">.</span> : totalPaidReservations}</h3>
            </div>
            <div className="dashboard-contant">
              <p className="title">Total collections</p>
              <h3>{loadingDetails ? <span className="loading-dots">.</span> : totalPayment}</h3>
            </div>
          </div>
        </div>
      </div>

      <div className="reservation-idtable">
        <div className="search-div">
          <div className="reservation-wrapper">
            <input
              type="text"
              className="form-control reservation-search"
              placeholder="Hunter Name, Property Name, Landowner Name, Coupon Code, Date of Reservation, Purchase Date"
              value={searchInput}
              onKeyDown={handleKeyDown}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            {searchInput && (
              <button className="clear-btn" onClick={handleClearSearch}>×</button>
            )}
          </div>
          <button className="add-btn" onClick={handleSearch}>Search</button>
          <button className="download-btn" onClick={() => downloadCSV()}>{loading ? <div className="loader"></div> : 'Download CSV'}</button>
        </div>

        <div className="add-form pagination-table">
          <div className="add-table">
            <table className="table">
              <thead>
                <tr>

                  {/* <th>Sr No.</th> */}
                  <th onClick={() => sortreservations('hunter_name')}>Hunter Name &nbsp;
                    {sortConfig.key === "hunter_name" ? (
                      sortConfig.direction === "ascending" ? (
                        <i className="fas fa-arrow-up"></i>
                      ) : (
                        <i className="fas fa-arrow-down"></i>
                      )
                    ) : (
                      <i className="fas fa-arrow-down"></i>
                    )}</th>

                  <th>Hunter Phone No.</th>
                  <th>Hunter Email</th>

                  <th onClick={() => sortreservations('landowner_name')}>Landowner Name &nbsp;
                    {sortConfig.key === "landowner_name" ? (
                      sortConfig.direction === "ascending" ? (
                        <i className="fas fa-arrow-up"></i>
                      ) : (
                        <i className="fas fa-arrow-down"></i>
                      )
                    ) : (
                      <i className="fas fa-arrow-down"></i>
                    )}</th>
                  <th onClick={() => sortreservations('property_name')}>Property Name &nbsp;
                    {sortConfig.key === "property_name" ? (
                      sortConfig.direction === "ascending" ? (
                        <i className="fas fa-arrow-up"></i>
                      ) : (
                        <i className="fas fa-arrow-down"></i>
                      )
                    ) : (
                      <i className="fas fa-arrow-down"></i>
                    )}</th>
                  <th onClick={() => sortreservations('createdAt')}>Purchase date &nbsp;
                    {sortConfig.key === "createdAt" ? (
                      sortConfig.direction === "ascending" ? (
                        <i className="fas fa-arrow-up"></i>
                      ) : (
                        <i className="fas fa-arrow-down"></i>
                      )
                    ) : (
                      <i className="fas fa-arrow-down"></i>
                    )}</th>

                  <th onClick={() => sortreservations('ReservationDate')}>Reservation Date &nbsp;
                    {sortConfig.key === "ReservationDate" ? (
                      sortConfig.direction === "ascending" ? (
                        <i className="fas fa-arrow-up"></i>
                      ) : (
                        <i className="fas fa-arrow-down"></i>
                      )
                    ) : (
                      <i className="fas fa-arrow-down"></i>
                    )}</th>

                  <th>Slot Id</th>
                  <th>Cost</th>
                  <th>Coupon Applied</th>
                  <th>Coupon Discount</th>
                  {/* <th>Total</th> */}
                  <th>Payment Id</th>
                  <th>Reservation Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {getCurrentPageData().length === 0 ?
                  <td colSpan="10" style={{ textAlign: 'center' }}>
                    <div style={{
                      padding: '40px',
                      display: 'inline-block',
                    }}>
                      {status}
                    </div>
                  </td>
                  :
                  getCurrentPageData().map((reservation, index) => (
                    <tr key={reservation.id} className={
                      reservation.reservation_status === "Cancel"
                        ? "Action_Reservation_Cancel" :
                        reservation.reservation_status === "Cancelled"
                          ? "Action_Reservation_Cancelled"
                          : reservation.reservation_status === "Pending"
                            ? "Action_Reservation_Pending"
                            : "Action_Reservation_Booked"
                    }
                    >
                      {/* <td>{index+1}</td> */}
                      {reservation.hunter_name == "--" ?
                        <td>{reservation.hunter_name}</td>
                        :
                        <td onClick={() => gotohunterdetail(reservation.hunter)}><Link>{reservation.hunter_name}</Link></td>}
                      <td>{reservation.hunter != null ? reservation.hunter.phonenumber != null ? reservation.hunter.phonenumber.length > 0 ? reservation.hunter.phonenumber : '--' : '--' : '--'}</td>
                      <td>{reservation.hunter != null ? reservation.hunter.email != null ? reservation.hunter.email : '--' : '--'}</td>
                      <td>{reservation.landowner_name}</td>
                      <td onClick={() => gotopropertydetail(reservation, reservation.createdAts)}><Link>{reservation.property_name}</Link></td>
                      <td>{reservation.createdAt}</td>

                      <td>
                        {reservation.cart.map((name) => (
                          <p>
                            {/* {new Date(name.selecteddate).toLocaleDateString('en-US', {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit',
                            })} */}
                            {convertdate(name.selecteddate)}
                          </p>
                        ))}
                      </td>



                      {/* <td>{reservation.slot_id}</td> */}
                      <td>
                        {reservation.cart.map((name) => (
                          <p className="label_slot" onClick={() => gotopropertydetail(reservation)}>
                            <Link className="hunter-link">{name.selectedslot.name}</Link>
                            {name.isSlotCancelled ? <div className="label-cancel"><div className="lable-status ribbon-red">Cancelled</div></div> : null}
                          </p>
                        ))}
                      </td>



                      <td>${reservation.cost}</td>
                      <td>{reservation.coupon_applied ? reservation.coupon_applied : reservation.hunter && reservation.hunter.subscriptions && reservation.hunter.subscriptions.length && reservation.hunter.subscriptions[0].no_of_memberships ? "Membership" : "--"}</td>
                      {
                        reservation.coupon_discount_type === '--' ?
                          <td>--</td>
                          :
                          reservation.coupon_discount_type === 'percentage' ?
                            <td>{reservation.coupon_discount}%</td>

                            :
                            <td>${reservation.coupon_discount}</td>
                      }
                      {/* <td style={{textAlign : "right", }} >${reservation.total}</td> */}
                      <td>{reservation.payment_id}</td>
                      <td>
                        <button
                          className="reservation-actionbtn"
                        >
                          <i class={
                            reservation.reservation_status === "Cancelled"
                              ? "fa-solid fa-circle-xmark" :
                              reservation.reservation_status === "Pending" ?
                                'fa-regular fa-clock'
                                : "fa-solid fa-circle-check"
                          }></i>
                          {reservation.reservation_status}
                        </button>
                      </td>

                     {/* <td>
                        {reservation.reservation_status === "Cancelled" ? <button className="reservation-actionbtns">
                          <i className="fa-solid fa-circle-xmark"></i>
                          Cancelled
                        </button> : <button className="reservation-actionbtns" id={'cancellationreason_' + reservation.id} onClick={() => setShowCancelReservation(reservation)}>
                          <i className="fa-solid fa-circle-xmark"></i>
                          Cancel
                        </button>}
                      </td>*/}

                       <td>
                        <div className="manage-editdiv">
                          <button className="reservation-actionbtns" id={'cancellationreason_' + reservation._id}
                            onClick={() => handleEditClick(reservation)}>
                            <i className="fa-solid fa-circle-xmark"></i>Cancel Slot
                          </button>
                        </div>
                      </td> 
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>




          <div className="rowperpage">
            <div className="perpagecount">
              Row Per Page
              <select
                onChange={inputperPage}
                value={perpage}>
                <option value={10}>10</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={150}>150</option>
                <option value={200}>200</option>
              </select>
            </div>
            <ReactPaginate
              pageCount={Math.ceil(
                totalReservations / perpage
              )}
              pageRangeDisplayed={perpage}
              breakLabel={"..."}
              marginPagesDisplayed={2}
              onPageChange={handlereservationupcomingidPageClick}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>


        </div>
      </div>

      {showCancel ?
        <div className="managecou-popup">
          <div className="reservation-content res-delete">
            <i className="fa-solid fa-xmark" onClick={handlepopupclose}></i>
            <div className="reservation-contentdiv">
              <img src={Xicon} alt="Xicon" />
              <h1>Are you Sure?</h1>
              <p>Are you sure you want to cancel the reservation.</p>
              <div className="form-floating">
                <textarea
                  className="form-control"
                  placeholder="Leave a comment here"
                  id="floatingTextarea2"
                  name="cancelreason"
                  onChange={inputsHandler}
                  value={step1inputField.cancelreason}
                ></textarea>
                <label for="floatingTextarea2">Reason...</label>
              </div>
            </div>
            <div className="managecou-btn">
              <button className="btn1" onClick={handlepopupclose}>
                No
              </button>
              <button className="btn2" onClick={handlecancelreservation}>
                Yes
              </button>
            </div>
          </div>
        </div>
        : null}

      {showCancelLoader ?
        <div className="loader_section">
          <div className="res_loader">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
        : null
      }

    </div>
  );
}
