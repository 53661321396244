import { HunterImg, Landimg, Propertyimg, Reservationsimg } from "./Images"

import { useNavigate } from "react-router-dom";

import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import { useDispatch } from "react-redux";
import { fetchData } from "../redux/apiSlice";
import { toastify, userlogout } from '../redux/controller';
import 'react-toastify/dist/ReactToastify.css';

export default function Dashboard() {


  const hunter_navigate = useNavigate();
  const Landowner_navigate = useNavigate();
  const Property_navigate = useNavigate();
  const Reservations_navigate = useNavigate();

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [totalHunters, setTotalHunters] = useState(0);

  const [totalLandowners, setTotalLandowners] = useState(0);
  
  const [loadingDetails, setLoadingDetails] = useState(true);

  const [totalReservations, setTotalReservations] = useState(0);

  const [totalProperties, setTotalProperties] = useState(0);


  const usertoken = localStorage.getItem('usertoken');

  const effectRan = useRef(false);


  useEffect(() => {
    if (effectRan.current === false) {
      getDashboardData();
      return () => {
        effectRan.current = true;
      };
    }
  }, [dispatch, usertoken, navigate]);

  async function getDashboardData() {
    var dashboardData = await dispatch(
      fetchData({
        apiurl: "/auth/user/dashboard",
        headers: { "x-access-token": usertoken }
      })
    );

    console.log("dashboardData", dashboardData);

    setLoadingDetails(false)
    if (dashboardData && dashboardData.payload && dashboardData.payload.status === 200) {

      setTotalHunters(dashboardData.payload.hunters);
      setTotalLandowners(dashboardData.payload.landowners);
      setTotalProperties(dashboardData.payload.properties);
      setTotalReservations(dashboardData.payload.reservations);
    }else
    if (dashboardData.payload.response && dashboardData.payload.response.message) {
      if (dashboardData.payload.response.message === "Invalid token provided!") {
        toastify({ message: 'Session expired' });
        userlogout();
        navigate('/');
      }
    }
  }


  function handleHunter() {
    hunter_navigate("/hunters");
  }

  function handleLandowners() {
    Landowner_navigate("/landowners");
  }

  function handleProperty() {
    Property_navigate("/properties");
  }

  function handleReservations() {
    Reservations_navigate("/reservations");
  }

  return (
    <div className="dashboard-main">
      <div className="dashboard-section">
        <div className="dashboard-subsection" onClick={handleHunter}>
          <div className="dashboard-contant">
            <p>Total No of Hunters</p>

            <h1>{loadingDetails ? <span className="loading-dots">.</span> : totalHunters}</h1>
          </div>
          <div className="dashboard-img">
            <img src={HunterImg} alt="HunterImg" />
          </div>
        </div>
        <div className="dashboard-subsection" onClick={handleLandowners}>
          <div className="dashboard-contant">
            <p>Total No of Landowners</p>
            {/* <h1>{totalLandowners}</h1> */}
            <h1>{loadingDetails ? <span className="loading-dots">.</span> : totalLandowners}</h1>
          </div>
          <div className="dashboard-img">
            <img src={Landimg} alt="Landimg" />
          </div>
        </div>
      </div>

      <div className="dashboard-section">
        <div className="dashboard-subsection" onClick={handleProperty}>
          <div className="dashboard-contant">
            <p>Total No of Properties </p>
            {/* <h1>{totalProperties}</h1> */}
            <h1>{loadingDetails ? <span className="loading-dots">.</span> : totalProperties}</h1>
          </div>
          <div className="dashboard-img">
            <img src={Propertyimg} alt="Propertyimg" />
          </div>
        </div>
        <div className="dashboard-subsection" onClick={handleReservations}>
          <div className="dashboard-contant">
            <p>Total No of Reservations</p>
            {/* <h1>{totalReservations}</h1> */}
            <h1>{loadingDetails ? <span className="loading-dots">.</span> : totalReservations}</h1>
          </div>
          <div className="dashboard-img">
            <img src={Reservationsimg} alt="Reservationsimg" />
          </div>
        </div>
      </div>
    </div>
  );
}
